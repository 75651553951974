import React, { useReducer } from "react";
import {
  GET_ALL_SALES,
  PAUSE_ORDER,
  SHOW_ERRORS_API,
  VERIFY_ORDER,
} from "../../types/Index";
import SalesContext from "./SalesContext";
import SalesReducer from "./SalesReducer";
import clientAxiosWos from "../../config/AxiosWos";
import clientAxiosWec from "../../config/Axios";
import clientAxiosWasys from "../../config/AxiosWASYS";
import Swal from "sweetalert2";
import clienteAxiosWec from "../../config/Axios";
const SalesState = ({ children }) => {
  //estado_inicial
  const initialState = {
    orders: [],
    success: false,
    ErrorsApi: [],
  };
  const [state, dispatch] = useReducer(SalesReducer, initialState);
  const GetSales = (system) => {
    if (system === "WEC") {
      clientAxiosWec
        .get(`/orders/Verification`)
        .then((res) => {
          dispatch({
            type: GET_ALL_SALES,
            payload: {
              orders: res.data.orders,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: SHOW_ERRORS_API,
            payload: error.response.data.message,
          });
        });
    } else if (system === "WOS") {
      clientAxiosWos
        .get("/indexApproveOrder")
        .then((res) => {
          dispatch({
            type: GET_ALL_SALES,
            payload: {
              orders: res.data.orders,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (system === "WASYS") {
      clientAxiosWasys
        .get("/sales-wasys")
        .then((res) => {
          dispatch({
            type: GET_ALL_SALES,
            payload: {
              orders: res.data.orders,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const VerifyOrder = (id, history) => {
    let system = localStorage.getItem("system");
    if (system === "WEC") {
      let url = `/orders/VerificationOrder/${id}`;
      Swal.fire("Verificando...!");
      Swal.showLoading();
      clientAxiosWec
        .put(url)
        .then((res) => {
          Swal.fire({
            title: "Verificada",
            text: res.data.message,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          dispatch({
            type: VERIFY_ORDER,
            payload: id,
          });
          history.push("/");
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: SHOW_ERRORS_API,
            payload: error,
          });
        });
    } else if (system === "WASYS") {
      let url = `/verified-sale-wasys/${id}`;
      Swal.fire("Verificando...!");
      Swal.showLoading();
      clientAxiosWasys
        .get(url)
        .then((res) => {
          Swal.fire({
            title: "Verificada",
            text: res.data.message,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          dispatch({
            type: VERIFY_ORDER,
            payload: id,
          });
          history.push("/");
        })
        .catch((error) => {
          dispatch({
            type: SHOW_ERRORS_API,
            payload: error,
          });
        });
    } else if (system === "WOS") {
      let url = `/verificationSales/${id}`;
      Swal.fire("Verificando...!");
      Swal.showLoading();
      clientAxiosWos
        .post(url)
        .then((res) => {
          Swal.fire({
            title: "Verificada",
            text: res.data.data,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          dispatch({
            type: VERIFY_ORDER,
            payload: id,
          });
          history.push("/");
        })
        .catch((error) => {
          dispatch({
            type: SHOW_ERRORS_API,
            payload: error,
          });
          console.log(error);
        });
    }
  };

  const PauseOrder = (data, history) => {
    let url = `/verification/create-paused`;
    let id = data.order_id;
    Swal.fire("Pausando...!");
    Swal.showLoading();
    clientAxiosWec
      .post(url, data)
      .then((res) => {
        Swal.fire({
          title: "Pausada",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: PAUSE_ORDER,
          payload: id,
        });
        history.push("/");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error,
        });
      });
  };

  const saveVerified = (data) => {
    console.log(data, "la data");
    let url = "/verification/create-verification";
    clienteAxiosWec
      .post(url, data)
      .then((res) => {
        console.log(res.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <SalesContext.Provider
      value={{
        orders: state.orders,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetSales,
        VerifyOrder,
        PauseOrder,
        saveVerified,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export default SalesState;
