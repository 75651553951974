import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Grid } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function CustomizedTables({ products }) {
  const [showMore, setShowMore] = React.useState(9);
  const [more, setMore] = React.useState(false);
  const handleShowMore = () => {
    setShowMore(showMore + 25);
    setMore(true);
  };
  const handleShowLess = () => {
    setShowMore(showMore - 25);
    setMore(false);
  };
  function ordenarPorAtributos(a, b) {
    if (a.cantidad_order === a.cantidad && b.cantidad_order !== b.cantidad) {
      return 1; // a va después de b
    } else if (
      a.cantidad_order !== a.cantidad &&
      b.cantidad_order === b.cantidad
    ) {
      return -1; // a va antes de b
    } else {
      return 0; // no se cambia el orden
    }
  }
  products.sort(ordenarPorAtributos);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Codigo de barras</StyledTableCell>
            <StyledTableCell align="center">Producto</StyledTableCell>
            <StyledTableCell align="center">Cant. Comprada</StyledTableCell>
            <StyledTableCell align="center">Cant. Agregada</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.slice(0, showMore).map((row, index) => (
            <>
              {row.cantidad_order === row.cantidad ? (
                <TableRow key={index} sx={{ backgroundColor: "#e8f5e9" }}>
                  <TableCell component="th" scope="row" align="center">
                    {row.barcode}
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.cantidad_order}</TableCell>
                  <TableCell align="center">{row.cantidad}</TableCell>
                </TableRow>
              ) : (
                <TableRow key={index} sx={{ backgroundColor: "#ef9a9a" }}>
                  <TableCell component="th" scope="row" align="center">
                    {row.barcode}
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.cantidad_order}</TableCell>
                  <TableCell align="center">{row.cantidad}</TableCell>
                </TableRow>
              )}
            </>
          ))}
        </TableBody>
      </Table>
      {showMore < products.length && more === false ? (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ display: "flex", justifyContent: "center", margin: 3 }}
          >
            <Button
              onClick={handleShowMore}
              variant="outlined"
              sx={{
                color: "#880e4f",
                backgroundColor: "#f8bbd0",
                borderColor: "#e91e63",
                "&:hover": {
                  color: "#880e4f",
                  backgroundColor: "#f8bbd0",
                  borderColor: "#e91e63",
                },
              }}
            >
              Ver 25 registros más
            </Button>
          </Grid>
        </Grid>
      ) : (
        products.length > 0 &&
        more === true && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex", justifyContent: "center", margin: 3 }}
            >
              <Button
                onClick={handleShowLess}
                variant="outlined"
                sx={{
                  color: "#880e4f",
                  backgroundColor: "#f8bbd0",
                  borderColor: "#e91e63",
                  "&:hover": {
                    color: "#880e4f",
                    backgroundColor: "#f8bbd0",
                    borderColor: "#e91e63",
                  },
                }}
              >
                Ver 25 registros menos
              </Button>
            </Grid>
          </Grid>
        )
      )}
    </TableContainer>
  );
}
