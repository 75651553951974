import React from "react";
import AuthState from "./context/Auth/AuthState";
import SalesState from "./context/Sales/SalesState";
import AppRouter from "./routes/AppRouter";
const VerificationApp = () => {
  return (
    <AuthState>
      <SalesState>
        <AppRouter />
      </SalesState>
    </AuthState>
  );
};

export default VerificationApp;
