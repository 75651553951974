import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardOrder({ order }) {
  const formatDate = (date) => {
    const newDate = new Date(date).toISOString();
    const dateopt = newDate.slice(0, 10).split("-");
    return dateopt[2] + "/" + dateopt[1] + "/" + dateopt[0];
  };
  return (
    <Card sx={{ boxShadow: 5, height: "310px" }}>
      <Alert severity="success" icon={false}>
        <AlertTitle>
          Folio venta: {""}
          <strong>
            # {""}
            {order.folio}
          </strong>
        </AlertTitle>
      </Alert>
      <CardContent>
        <Alert severity="info" icon={false}>
          <AlertTitle sx={{ textAlign: "center" }}>
            Cliente: {""}
            <strong>
              {""}
              {order.client_id.fullname}
            </strong>
          </AlertTitle>
        </Alert>
        <br />
        <Alert severity="warning" icon={false} sx={{ textAlign: "center" }}>
          Fecha Compra: {formatDate(order.fecha_compra)}
        </Alert>
      </CardContent>
      <CardActions disableSpacing>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Link to={`/OrderVerification/${order._id}`}>
              <Button
                sx={{
                  backgroundColor: "#f06292",
                  color: "white",
                  "&:hover": { backgroundColor: "#f06292", color: "white" },
                }}
              >
                Verificar orden
              </Button>
            </Link>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
