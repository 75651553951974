import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { useDebounce } from "use-debounce";
import Swal from "sweetalert2";
import TableProductsVerified from "../Tables/TableProductsVerified";

const Barcode = ({ productsOrder, productsVerified, saveProductsVerified }) => {
  const errorSound = require("../../assets/audio/error_sound.mp3");
  const [barcode, saveBarcode] = useState("");
  const [debounceBarcode] = useDebounce(barcode, 200);
  const handleChangeBarcode = (e) => {
    saveBarcode(e.target.value);
  };
  const playSound = () => {
    const audio = new Audio(errorSound);
    audio.play();
  };

  useEffect(() => {
    filterProductsExist();
  }, [debounceBarcode]);
  const filterProductsExist = () => {
    if (debounceBarcode !== "") {
      var producto = [];
      producto = productsOrder.filter(
        (p) => p.product.barcode === debounceBarcode
      );

      if (!producto.length) {
        playSound();
        Swal.mixin({
          toast: true,
          background: "red",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          //timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "error",
          html: "<h2>¡Lo sentimos!</h2><h2>El producto que escaneaste no existe dentro del pedido! Verifica que sea el producto correcto o cambialo</h2>",
        });
        saveBarcode("");
        return;
      }
      producto[0].value = producto[0].product._id;
      const position = productsVerified
        .map(function (e) {
          return e.product_id;
        })
        .indexOf(producto[0]._id);
      let productoResultado = {};
      productoResultado.barcode = producto[0].product.barcode;
      productoResultado.name = producto[0].product.name;
      productoResultado.cantidad = 1;
      productoResultado.cantidad_order = producto[0].quantity;
      productoResultado.product_id = producto[0]._id;
      productoResultado.status = false;
      if (position === -1) {
        productoResultado.product_id = producto[0]._id;
        productoResultado.cantidad = 1;
        if (productoResultado.cantidad === productoResultado.cantidad_order) {
          productoResultado.status = true;
        }
        saveProductsVerified([productoResultado, ...productsVerified]);
        saveBarcode("");
        Swal.mixin({
          toast: true,
          background: "#388e3c",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `El producto se agregó correctamente `,
        });
      } else {
        if (
          productsVerified[position].cantidad ===
          productsVerified[position].cantidad_order
        ) {
          Swal.mixin({
            toast: true,
            background: "red",
            color: "white",
            iconColor: "white",
            position: "top-end",
            showConfirmButton: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          }).fire({
            icon: "error",
            //title: `¡Lo sentimos!`,
            html: "            <h2>¡Lo sentimos!</h2><h2><b>Ya no puedes agregar más piezas de este producto a la orden, ya haz verificado las piezas requeridas en el pedido!</b></h2>",
          });
          saveBarcode("");
          return;
        }
        let quantityProduct = productsVerified[position].cantidad;
        var new_quantity_prod = (quantityProduct += 1);
        productsVerified[position].cantidad = new_quantity_prod;
        if (new_quantity_prod === productsVerified[position].cantidad_order) {
          productsVerified[position].status = true;
        }
        saveProductsVerified([...productsVerified]);
        saveBarcode("");
        Swal.mixin({
          toast: true,
          background: "#388e3c",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `El producto se agregó correctamente  `,
        });
      }
    }
  };

  return (
    <>
      <TextField
        placeholder="Usa el lector de codigos de barra"
        variant="outlined"
        required
        fullWidth
        value={barcode}
        id="barcode"
        label="Código De Barras"
        name="barcode"
        autoComplete="off"
        autoFocus
        onChange={(e) => {
          handleChangeBarcode(e);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CropFreeIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
      </Grid>
    </>
  );
};

export default Barcode;
