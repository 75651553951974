import React from "react";
import Header from "./Header";
import "./styles.css";
const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div style={{ margin: 10 }}>{children}</div>
    </div>
  );
};

export default Layout;
