import React, { useContext, useEffect, useState } from "react";
import "./stylesLogin.css";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import AuthContext from "../../context/Auth/AuthContext";
import SelectOptionLogin from "../../Components/Selects/SelectOptionLogin";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
const Login = () => {
  const history = useHistory();
  const { iniciarSesion, autenticado } = useContext(AuthContext);
  const [system, saveSystem] = useState(null);
  const detectarCambiosSystem = (e) => {
    saveSystem(e);
  };
  //CONTRASEÑA
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (autenticado) {
      history.push("/");
    }
  }, [autenticado, history]);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      formData.system = system;
      iniciarSesion(formData);
    },
  });
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="backgroundLogin"></div>
      </Grid> */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="backgroundLogin"
      >
        <Grid container justifyContent="center">
          <div className="centrado">
            <Box
              className="box caja"
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <Typography textAlign="center">
                <img
                  src={require("../../assets/img/imgloading.png")}
                  width="150px"
                  height="150px"
                />
              </Typography>
              <Typography
                fontWeight="bold"
                textAlign="center"
                variant="h3"
                className="title"
              >
                Iniciar Sesion
              </Typography>
              <Box className="box-center">
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <SelectOptionLogin
                      detectarCambiosSystem={detectarCambiosSystem}
                    />
                  </Grid>
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <TextField
                      fullWidth
                      id="email"
                      label="Correo Electronico"
                      name="email"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      error={formik.errors?.email ? true : false}
                    />
                  </Grid>
                  <Grid item xs={10} md={10} lg={10} xl={10}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        htmlFor="password"
                        error={formik.errors?.password ? true : false}
                      >
                        Contraseña
                      </InputLabel>
                      <OutlinedInput
                        error={formik.errors?.password ? true : false}
                        id="password"
                        name="password"
                        type={values.showPassword ? "text" : "password"}
                        value={values.password}
                        onChange={(event) => {
                          setValues({
                            ...values,
                            password: event.target.value,
                          });
                          formik.handleChange(event);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Contraseña"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Button
                      fullWidth
                      type="submit"
                      sx={{
                        backgroundColor: "#F871A0",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#F871A0",
                          color: "white",
                        },
                      }}
                      className="boton-login"
                    >
                      Iniciar sesion
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
function initialValues() {
  return {
    email: "",
    password: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string().email(true).required("Correo requerido"),
    password: Yup.string().min(8).required("Contraseña requerida"),
  };
}

export default Login;
