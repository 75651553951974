//autenticacion
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const CERRAR_SESION = "CERRAR_SESION";
export const INICIAR_SESION = "INICIAR_SESION";
//Errors API
export const SHOW_ERRORS_API = "SHOW_ERRORS_API";
//ordernes- ventas
export const GET_ALL_SALES = "GET_ALL_SALES";
export const VERIFY_ORDER = "VERIFY_ORDER";
export const APROVE_VERIFY_ORDER = "APROVE_VERIFY_ORDER";
export const PAUSE_ORDER = "PAUSE_ORDER";
