import React, { useContext } from "react";
import Layout from "../../Layout/Layout";
import { Typography } from "@mui/material";
import AuthContext from "../../context/Auth/AuthContext";
import CardWelcome from "../../Components/Dashboard/CardWelcome";
import IndexOrders from "../Orders/IndexOrders";
const Home = () => {
  const system = localStorage.getItem("system");
  const { usuario } = useContext(AuthContext);
  return (
    <Layout>
      {/* <CardWelcome /> */}
      <IndexOrders />
    </Layout>
  );
};

export default Home;
