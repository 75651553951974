import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Login from "../Layout/Auth/Login";
import { PublicRoutes } from "./PublicRoutes";
import { PrivateRoutes } from "./PrivateRoutes";
import AuthContext from "../context/Auth/AuthContext";
import { VerificationRoutes } from "./VerificationRoutes";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import SpinnerLoading from "../Components/Loading/SpinnerLoading";
const AppRouter = () => {
  const { autenticado, usuarioAutenticado, cargando } = useContext(AuthContext);
  const system = localStorage.getItem("system");
  useEffect(() => {
    usuarioAutenticado();
  }, []);
  if (cargando) {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
          sx={{
            width: "105%",
            height: "177%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SpinnerLoading />
        </Box>
      </Grid>
    );
  }
  return (
    <>
      <Router>
        <Switch>
          <PublicRoutes
            exact
            path="/iniciar-sesion"
            component={Login}
            isAuthenticated={autenticado}
          />
          <PrivateRoutes
            path="/"
            component={VerificationRoutes}
            isAuthenticated={autenticado}
          />
        </Switch>
      </Router>
    </>
  );
};

export default AppRouter;
