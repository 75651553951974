import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../containers/Home/Home";
import ViewVerification from "../containers/Orders/Verification/ViewVerification";
export const VerificationRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/OrderVerification/:id" component={ViewVerification} />
    </Switch>
  );
};
