import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
const SelectProductsOrder = (props) => {
  //se desestructuran los props
  const {
    productsOrder,
    productId,
    productsVerified,
    saveProductsVerified,
    saveProductId,
  } = props;
  //funcion detectar cambios en el select
  const detectarCambiosProduct = (value) => {
    props.detectarCambiosProduct(value);
  };
  //constante de stilos en el select, para que se le quite la transparencia
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  //personalizacion de las opciones del select
  const nuevoArreglo = productsOrder.map((p) => {
    const product = `${p.product.name} - ${p.quantity}  pzas `;
    return { value: p.product._id, label: product, barcode: p.product.barcode };
  });
  //funcionalidad agregar producto al array de verificados
  const AgregarProductsArray = () => {
    var producto = [];
    producto = productsOrder.filter((p) => p.product._id === productId);
    console.log(producto);
    if (!producto.length) {
      Swal.fire({
        title: "error",
        text: "Ocurrio un error al consultar la informacion del producto",
        icon: "error",
      });
    }
    producto[0].value = producto[0]._id;
    const position = productsVerified
      .map(function (e) {
        return e.product_id;
      })
      .indexOf(producto[0]._id);
    let productoResultado = {};
    productoResultado.barcode = producto[0].product.barcode;
    productoResultado.name = producto[0].product.name;
    productoResultado.cantidad = 1;
    productoResultado.cantidad_order = producto[0].quantity;
    productoResultado.product_id = producto[0]._id;
    productoResultado.status = false;
    if (position === -1) {
      productoResultado.product_id = producto[0]._id;
      productoResultado.cantidad = 1;
      if (productoResultado.cantidad === productoResultado.cantidad_order) {
        productoResultado.status = true;
      }
      saveProductsVerified([productoResultado, ...productsVerified]);
      saveProductId(null);
      Swal.mixin({
        toast: true,
        background: "#388e3c",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "success",
        title: `El producto se agregó correctamente `,
      });
    } else {
      if (
        productsVerified[position].cantidad ===
        productsVerified[position].cantidad_order
      ) {
        Swal.mixin({
          toast: true,
          background: "red",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "error",
          //title: `¡Lo sentimos!`,
          html: "            <h2>¡Lo sentimos!</h2><h2><b>Ya no puedes agregar más piezas de este producto a la orden, ya haz verificado las piezas requeridas en el pedido!</b></h2>",
        });
        saveProductId(null);
        return;
      }
      let quantityProduct = productsVerified[position].cantidad;
      var new_quantity_prod = (quantityProduct += 1);
      productsVerified[position].cantidad = new_quantity_prod;
      if (new_quantity_prod === productsVerified[position].cantidad_order) {
        productsVerified[position].status = true;
      }
      saveProductsVerified([...productsVerified]);
      saveProductId(null);
      Swal.mixin({
        toast: true,
        background: "#388e3c",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "success",
        title: `El producto se agregó correctamente  `,
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
        <Select
          className="basic-single"
          classNamePrefix="select"
          name="product"
          defaultValue={{ label: "Selecciona un producto ", value: "" }}
          placeholder="Selecciona producto"
          styles={selectStyles}
          options={nuevoArreglo}
          onChange={(value) => detectarCambiosProduct(value)}
        />
      </Grid>
      {productId !== null && (
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Button variant="contained" fullWidth onClick={AgregarProductsArray}>
            Agregar
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default SelectProductsOrder;
