import React, { useContext, useEffect, useState } from "react";
import SalesContext from "../../context/Sales/SalesContext";
import { Grid, Paper, TextField, Typography } from "@mui/material";
import CardOrder from "../../Components/Cards/Sales/CardOrder";
import LoadingComponent from "../../Components/Loading/SpinnerLoading";
import { useDebounce } from "use-debounce";
import NoDataComponents from "../../Components/Loading/NoDataComponents";
import SearchingComponent from "../../Components/Loading/SearchingComponent";
import NoResultsComponent from "../../Components/Loading/NoResultsComponent";
import TabsOrder from "../Orders/Verification/TabsOrder";
const IndexOrders = () => {
  const { orders, GetSales } = useContext(SalesContext);
  const [system, setSystem] = useState(localStorage.getItem("system") || null);

  useEffect(() => {
    if (system !== null) {
      GetSales(system);
    }
  }, [system]);
  /**busqueda*/
  const [magicWord, setMagicWord] = useState(null);
  const [debounceWord] = useDebounce(magicWord, 800);
  const [searchOrder, saveSearchOrder] = useState([]);
  const handleSearchOrder = () => {
    try {
      let searchResult;
      if (magicWord !== null) {
        searchResult = orders.filter(
          (order) =>
            order.folio
              .toString()
              .toLowerCase()
              .includes(debounceWord.toLowerCase()) ||
            order.client_id.fullname
              .toString()
              .toLowerCase()
              .includes(debounceWord.toLowerCase())
        );
      }
      saveSearchOrder(searchResult);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleSearchOrder();
  }, [debounceWord]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Paper className="paper">
            <Typography fontWeight="bold" fontSize="30px" textAlign="center">
              Ventas {""}
              {system === "WEC"
                ? "Wapizima Ecommerce"
                : system === "WOS"
                ? "Wapizima Online School"
                : system === "WASYS" && "Wapizima Sys"}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <TabsOrder />
      </Grid> */}
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {orders.length ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              type="search"
              fullWidth
              label="Buscar orden por folio y/o cliente"
              onChange={(e) => {
                setMagicWord(e.target.value);
                saveSearchOrder(null);
              }}
            />
          </Grid>
        ) : (
          <></>
        )}
        {!magicWord &&
          (!orders.length ? (
            <NoDataComponents />
          ) : (
            <Grid container spacing={2} sx={{ margin: 2 }}>
              {orders.map((order) => (
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} key={order._id}>
                  <CardOrder order={order} />
                </Grid>
              ))}
            </Grid>
          ))}
        {magicWord &&
          (!searchOrder ? (
            <SearchingComponent />
          ) : searchOrder.length === 0 ? (
            <NoResultsComponent />
          ) : (
            <Grid container spacing={2} sx={{ margin: 2 }}>
              {searchOrder.map((order) => (
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} key={order._id}>
                  <CardOrder order={order} />
                </Grid>
              ))}
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default IndexOrders;
