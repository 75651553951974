import { Button, Chip, Grid } from "@mui/material";
import React from "react";
import PausedOrder from "./PausedOrder";

const InfoOrder = ({ order, productsOrder, productsVerified }) => {
  const { client_id, folio, total } = order;
  const [openPaused, setOpenPaused] = React.useState(false);

  const handleClickOpenPaused = () => {
    setOpenPaused(true);
  };

  const handleClosePaused = () => {
    setOpenPaused(false);
  };
  return (
    <>
      {order ? (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Chip
              label={`Folio Venta: ${folio ? folio : "cargando..."}`}
              variant="outlined"
              color="success"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Chip
              label={`Cliente: ${
                client_id ? client_id.fullname : "Cargando..."
              } `}
              variant="outlined"
              color="success"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Chip
              label={`Total: $ ${total ? total : "Cargando..."} `}
              variant="outlined"
              color="success"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={handleClickOpenPaused}
            >
              Pausar Verificacion
            </Button>
          </Grid>
        </Grid>
      ) : (
        "Cargando..."
      )}
      {order && (
        <PausedOrder
          modal={openPaused}
          handleClose={handleClosePaused}
          order={order}
          productsOrder={productsOrder}
          productsVerified={productsVerified}
        />
      )}
    </>
  );
};

export default InfoOrder;
