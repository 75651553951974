import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";

export default function SelectOptionLogin(props) {
  const systems = [
    { value: "WOS", name: "Wapizima Online School" },
    { value: "WASYS", name: "Wapizima Sys" },
    { value: "WEC", name: " Wapizima Ecommerce" },
  ];
  const [selectedSystem, setSelectedSystem] = useState("");
  const detectarCambiosSystem = (e) => {
    props.detectarCambiosSystem(e.target.value);
    setSelectedSystem(e.target.value);
  };
  return (
    <FormControl fullWidth size="large">
      <InputLabel id="demo-simple-select-label">Selecciona Sistema</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedSystem}
        label="Selecciona Sistema"
        onChange={detectarCambiosSystem}
      >
        {systems.map((system) => (
          <MenuItem key={system.value} value={system.value}>
            {system.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
