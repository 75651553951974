import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import AuthContext from "../context/Auth/AuthContext";
function ResponsiveAppBar() {
  const { cerrarSesion } = React.useContext(AuthContext);

  return (
    <AppBar position="static" sx={{ backgroundColor: "#f8bbd0" }}>
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 5 }}>
            <img src={require("../assets/img/logo.webp")} height="80px" />
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 2, flexGrow: 1 }}>
            <img
              src={require("../assets/img/imgloading.png")}
              width="80px"
              height="80px"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Tooltip title="Cerrar Sesion">
              <IconButton onClick={cerrarSesion} sx={{ p: 0 }}>
                <LogoutIcon sx={{ color: "white", fontSize: "35px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
