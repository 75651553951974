import {
  CERRAR_SESION,
  LOGIN_ERROR,
  LOGIN_EXITOSO,
  OBTENER_USUARIO,
  OBTENER_USUARIO_WASYS,
  OBTENER_USUARIO_WOS,
} from "../../types/Index";

export default (state, action) => {
  switch (action.type) {
    case LOGIN_EXITOSO:
      if (action.system === "WEC") {
        localStorage.setItem("token", action.payload.token);
      } else if (action.system === "WOS") {
        localStorage.setItem("token", action.payload.token);
      } else if ((action.system = "WASYS")) {
        localStorage.setItem("token", action.payload.token);
      }
      localStorage.setItem("system", action.system);
      return {
        ...state,
        autenticado: true,
        cargando: false,
        usuario: action.payload,
      };
    case OBTENER_USUARIO:
      return {
        ...state,
        autenticado: true,
        usuario: action.payload,
        cargando: false,
      };
    case LOGIN_ERROR:
    case CERRAR_SESION:
      localStorage.removeItem("token");
      localStorage.removeItem("system");
      return {
        ...state,
        token: null,
        usuario: null,
        autenticado: false,
        cargando: false,
      };
    default:
      return state;
  }
};
