import { GET_ALL_SALES, PAUSE_ORDER, VERIFY_ORDER } from "../../types/Index";

export default function SalesReducer(state, action) {
  switch (action.type) {
    case GET_ALL_SALES:
      return {
        ...state,
        orders: action.payload.orders,
        ErrorsApi: [],
        success: false,
      };
    case VERIFY_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) =>
          order._id === action.payload._id ? { ...action.payload } : order
        ),
        ErrorsApi: [],
        success: true,
      };
    case PAUSE_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) =>
          order._id === action.payload._id ? { ...action.payload } : order
        ),
        ErrorsApi: [],
        success: true,
      };
    default:
      return state;
  }
}
