import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material/";

const useStyles = makeStyles({
  img_data: {
    margin: "auto",
    height: "40%",
  },
});

export default function NoResultsComponent() {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ display: "flex", justifyItems: "center", marginTop: -40 }}
    >
      <img
        src={require("../../assets/gif/noResults.gif")}
        className={classes.img_data}
        alt="noreult"
      />
    </Grid>
  );
}
