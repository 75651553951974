import * as React from "react";
import Radio from "@mui/material/Radio";
import { Grid, Typography } from "@mui/material";

export default function Options({ setSelectedValue, selectedValue }) {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Typography>Selecciona una opcion para agregar el producto</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        sx={{ display: "flex", justifyContent: "center", marginTop: -3 }}
      >
        <Typography sx={{ marginTop: 1 }}>C. Barras</Typography>
        <Radio
          checked={selectedValue === "Barcode"}
          onChange={handleChange}
          value="Barcode"
          name="radio-buttons"
          inputProps={{ "aria-label": "Barcode" }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        sx={{ display: "flex", justifyContent: "center", marginTop: -3 }}
      >
        <Typography sx={{ marginTop: 1 }}>Nombre</Typography>
        <Radio
          checked={selectedValue === "Name"}
          onChange={handleChange}
          value="Name"
          name="radio-buttons"
          inputProps={{ "aria-label": "Name" }}
        />
      </Grid>
    </Grid>
  );
}
