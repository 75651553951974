import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import { Button, Grid, Typography } from "@mui/material";
import Barcode from "../../../Components/Barcode/Barcode";
import clientAxiosWec from "../../../config/Axios";
import clientAxiosWos from "../../../config/AxiosWos";
import clientAxiosWasys from "../../../config/AxiosWASYS";
import TableProductsVerified from "../../../Components/Tables/TableProductsVerified";
import { useForm } from "react-hook-form";
import SalesContext from "../../../context/Sales/SalesContext";
import { useHistory } from "react-router-dom";
import SpinnerSmallComponent from "../../../Components/Loading/SpinnerSmallComponent";
import InfoOrder from "./InfoOrder";
import Options from "./Options";
import SelectProductsOrder from "../../../Components/Selects/SelectProductsOrder";
const ViewVerification = (props) => {
  const id = props.match.params.id;
  const system = localStorage.getItem("system");
  const { VerifyOrder, saveVerified } = useContext(SalesContext);
  const history = useHistory();
  const [order, saveOrder] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  const [productsOrder, saveProductsOrder] = useState([]);
  useEffect(() => {
    let url = `/orders/Verification/${id}`;
    if (system === "WEC") {
      clientAxiosWec
        .get(url)
        .then((res) => {
          saveOrder(res.data.order);
          saveProductsOrder(res.data.order.products_list);
          spinnerCargando(true);
        })
        .catch((error) => {
          console.log(error);
          spinnerCargando(true);
        });
    } else if (system === "WOS") {
      clientAxiosWos
        .get(`/showApproveOrder/${id}`)
        .then((res) => {
          saveOrder(res.data.order);
          saveProductsOrder(res.data.order.products_list);
          spinnerCargando(true);
        })
        .catch((error) => {
          console.log(error);
          spinnerCargando(true);
        });
    } else if (system === "WASYS") {
      clientAxiosWasys
        .get(`show-sale-wasys/${id}`)
        .then((res) => {
          saveOrder(res.data.order);
          saveProductsOrder(res.data.order.products_list);
          spinnerCargando(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);
  const [productsVerified, saveProductsVerified] = useState([]);
  const lengthVerified =
    productsOrder.length === productsVerified.length ? true : false;
  const todoCompleted =
    productsVerified.length > 0
      ? productsVerified.every(
          (item) =>
            typeof item.status !== "undefined" &&
            item.status !== null &&
            item.status === true &&
            lengthVerified
        )
      : false;
  //funcion para quitar los productos de una tabla y mandarlos a la otra
  const prodsVerified = productsVerified.map((product) => {
    let attribute = {
      status: product.status,
      barcode: product.barcode,
    };
    return attribute;
  });

  let setNewProducts = [];
  productsOrder.map((product) => {
    let test = prodsVerified.find(
      (pro) => pro.barcode === product.product.barcode && pro.status === true
    );
    if (test) {
    } else {
      setNewProducts.push(product);
    }
  });
  //select de optiones barcode o name
  const [selectedValue, setSelectedValue] = React.useState("Barcode");
  //select product
  const [productId, saveProductId] = useState(null);
  const detectarCambiosProduct = (value) => {
    saveProductId(value.value);
  };
  //funcion para enviar la peticion de guardado
  const { handleSubmit } = useForm();
  let newArray = [];
  productsOrder.map((p) => {
    newArray.push({
      product_id: p.product._id,
      barcode: p.product.barcode,
      name: p.product.name,
      quantity: p.quantity,
      name_client: order.client_id.fullname,
      total: order.total,
    });
  });
  const onSubmit = (data) => {
    data.productsOrder = newArray;
    data.productsVerified = productsVerified;
    data.system = system;
    data.folio = order.folio;
    data.total = order.total;
    data.order_id = order._id;
    data.client = order.client_id.fullname;
    VerifyOrder(id, history);
    saveVerified(data);
  };
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Typography fontWeight="bold" textAlign="center" fontSize="30px">
            Verificar Pedido
          </Typography>
        </Grid>
        {todoCompleted && (
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.code === "Enter") e.preventDefault();
              }}
              autoComplete="off"
            >
              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#b39ddb",
                  color: "white",
                  "&:hover": { backgroundColor: "#b39ddb", color: "white" },
                }}
              >
                Finalizar Verificación{" "}
              </Button>
            </form>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <InfoOrder
            order={order}
            productsOrder={productsOrder}
            prodsVerified={prodsVerified}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Options
                setSelectedValue={setSelectedValue}
                selectedValue={selectedValue}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              {selectedValue === "Barcode" ? (
                <Barcode
                  productsOrder={productsOrder}
                  productsVerified={productsVerified}
                  saveProductsVerified={saveProductsVerified}
                />
              ) : (
                selectedValue === "Name" && (
                  <SelectProductsOrder
                    productsOrder={setNewProducts}
                    productsVerified={productsVerified}
                    saveProductsVerified={saveProductsVerified}
                    detectarCambiosProduct={detectarCambiosProduct}
                    saveProductId={saveProductId}
                    productId={productId}
                  />
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography fontStyle="bold" textAlign="center" fontSize="35px">
            Productos de la orden
          </Typography>
          {productsOrder.length > 0 ? (
            <>
              <TableProductsOrder products={setNewProducts} />
            </>
          ) : (
            <SpinnerSmallComponent />
          )}
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography fontStyle="bold" textAlign="center" fontSize="35px">
            Productos Verificados
          </Typography>
          {/* <InfoQuantities
            original={original}
            copia={copia}
            productsOrder={productsOrder}
            productsVerified={prodsVerified}
          /> */}
          {productsVerified ? (
            <>
              <TableProductsVerified products={productsVerified} />
            </>
          ) : (
            <SpinnerSmallComponent />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ViewVerification;
