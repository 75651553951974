import React, { useReducer } from "react";
import AuthReducer from "./AuthReducer";
import AuthContext from "./AuthContext";
import tokenAuth from "../../config/TokenAuth";
import Swal from "sweetalert2";
import clienteAxiosWec from "../../config/Axios";
import clienteAxiosWos from "../../config/AxiosWos";
import clienteAxiosWasys from "../../config/AxiosWASYS";
import {
  CERRAR_SESION,
  LOGIN_ERROR,
  LOGIN_EXITOSO,
  OBTENER_USUARIO,
  SHOW_ERRORS_API,
} from "../../types/Index";
import TokenAutWasys from "../../config/TokenAutWasys";
import TokenAutWos from "../../config/TokenAuthWos";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: false,
    usuario: {},
    cargando: false,
    success: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    const system = localStorage.getItem("system");
    if (token) {
      if (system === "WEC") {
        tokenAuth(token);
      } else if (system === "WASYS") {
        TokenAutWasys(token);
      } else if (system === "WOS") {
        TokenAutWos(token);
      }
    }
    if (system === "WEC") {
      clienteAxiosWec
        .get("/admin/auth")
        .then(({ data }) => {
          dispatch({
            type: OBTENER_USUARIO,
            payload: data,
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          dispatch({
            type: LOGIN_ERROR,
          });
        });
    } else if (system === "WOS") {
      clienteAxiosWos
        .get("/user")
        .then(({ data }) => {
          dispatch({
            type: OBTENER_USUARIO,
            payload: data,
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
        });
    } else if (system === "WASYS") {
      clienteAxiosWasys
        .get("/user-verified")
        .then(({ data }) => {
          dispatch({
            type: OBTENER_USUARIO,
            payload: data,
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.error,
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
        });
      //
    }
  };
  //cuando el usuario inicia sesion
  const iniciarSesion = (datos) => {
    localStorage.setItem("system", datos.system);
    const formData = new FormData();
    formData.append("email", datos.email);
    formData.append("password", datos.password);
    if (datos.system === "WEC") {
      let url = "/admin/auth/login";
      clienteAxiosWec
        .post(url, formData)
        .then((res) => {
          dispatch({
            type: LOGIN_EXITOSO,
            payload: res.data,
            system: datos.system,
          });
          usuarioAutenticado();
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: error.response.data.message,
          });
          dispatch({
            type: SHOW_ERRORS_API,
          });
        });
    } else if (datos.system === "WASYS") {
      let url = "/login";
      clienteAxiosWasys
        .post(url, formData)
        .then((res) => {
          dispatch({
            type: LOGIN_EXITOSO,
            payload: res.data,
            system: datos.system,
          });
          usuarioAutenticado();
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            timer: 2500,
            showConfirmButton: false,
          });
        });
    } else if (datos.system === "WOS") {
      let url = "/login";
      clienteAxiosWos
        .post(url, formData)
        .then((res) => {
          dispatch({
            type: LOGIN_EXITOSO,
            payload: res.data,
            system: datos.system,
          });
          usuarioAutenticado();
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        });
    }
  };
  //Cierrra sesion del usuario
  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        success: state.success,
        cargando: state.cargando,
        iniciarSesion,
        usuarioAutenticado,
        cerrarSesion,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
