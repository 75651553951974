import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import SalesContext from "../../../context/Sales/SalesContext";
import { useContext } from "react";
export default function PausedOrder({
  order,
  modal,
  handleClose,
  productsOrder,
  productsVerified,
}) {
  const { PauseOrder } = useContext(SalesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const history = useHistory();
  let newArray = [];
  productsOrder.map((p) => {
    newArray.push({
      product_id: p.product._id,
      barcode: p.product.barcode,
      name: p.product.name,
      quantity: p.quantity,
      name_client: order.client_id.fullname,
      total: order.total,
    });
  });
  let system = localStorage.getItem("system");
  const onSubmit = (data, e) => {
    data.order_id = order._id;
    data.productsOrder = newArray;
    data.productsVerified = [];
    data.total = order.total;
    data.system = system;
    data.folio = order.folio;
    data.name_client = order.client_id.fullname;
    PauseOrder(data, history);
    handleClose();
    history.push("/");
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={modal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"Pausar verificación"}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="¿Porque se pausa?"
                  multiline
                  rows={4}
                  name="comment"
                  fullWidth
                  placeholder="Motivo por el cual se está pausando la verificacion de este pedido"
                  sx={{ marginTop: 4 }}
                  error={errors.name ? true : false}
                  helperText={errors?.name?.message}
                  {...register("comment", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                  })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#d32f2f",
                color: "white",
                "&:hover": { backgroundColor: "#d32f2f", color: "white" },
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleClose}
              autoFocus
              type="submmit"
              sx={{
                backgroundColor: "#43a047",
                color: "white",
                "&:hover": { backgroundColor: "#43a047", color: "white" },
              }}
            >
              Pausar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
