import clienteAxiosWasys from "./AxiosWASYS";

const TokenAutWasys = (tokenWasys) => {
  if (tokenWasys) {
    clienteAxiosWasys.defaults.headers.Authorization = `Bearer ${tokenWasys}`;
  } else {
    delete clienteAxiosWasys.defaults.headers.Authorization;
  }
};

export default TokenAutWasys;
