import clienteAxiosWos from "./AxiosWos";

const TokenAutWos = (tokenWos) => {
  if (tokenWos) {
    clienteAxiosWos.defaults.headers.Authorization = `Bearer ${tokenWos}`;
  } else {
    delete clienteAxiosWos.defaults.headers.Authorization;
  }
};

export default TokenAutWos;
